import React from "react"
import {Link, graphql} from "gatsby"
import Layout from "./Layout";
import {MDXRenderer} from "gatsby-plugin-mdx";

export default function BlogLayout({data}) {
    const {mdx} = data
    const {frontmatter, body} = mdx

    return (
        <Layout>
            <div className="blog-post-container container">
                Přečtete za {Math.round(body.length / 1234)} minut

                <div className="blog-post">
                    <h1>{frontmatter.title}</h1>
                    <h2>{frontmatter.date}</h2>
                    <MDXRenderer>{body}</MDXRenderer>
                </div>
                <hr />
                <Link to="/blog">Zpět na blog</Link>
            </div>

        </Layout>
    )
}

export const pageQuery = graphql`
query($slug: String!)
    {
        mdx(frontmatter
        :
        {
            slug: {
                eq: $slug
            }
        }
    )
        {
            body
            frontmatter
            {
                date(formatString
            :
                "DD.MM.YYYY"
            )
                slug
                title
            }
        }
    }
`
